<!-- 运费模板 -->

<template>
  <div class="shippingTemplate">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="nameValue" placeholder="请搜索运费模板名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-button class="btn" type="primary" @click="handleOpenDialog">多商品运费设置</el-button>
        <el-button class="btn" type="primary" @click="$router.push('/product/detail/addTemplate')">新增模板</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="templateName" label="模板名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="freightType	" label="计费方式">
            <template slot-scope="scope">
              <span>{{ scope.row.freightType == 1 ? '阶梯计费' : '固定运费' }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="180" show-overflow-tooltip prop="amountRule" label="计费规则"
            width="330"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productCount" label="关联商品数"></el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="createdTime" label="创建时间"></el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="updatedTime" label="最后修改时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="240" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleRelate(scope.row)">关联商品</el-button>
              <el-button size="small" class="btn" type="primary" plain @click="handleModify(scope.row)">修改</el-button>
              <el-button size="small" class="btn" type="danger" plain @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 多商品运费设置 -->
    <el-dialog title="多商品运费设置" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-row class="row">
          <el-col :span="24">
            <el-form-item label="一单多商品运费：" prop="fee">
              <el-select v-model="ruleForm.fee" placeholder="请选择" @change="handleSelectFee" clearable>
                <el-option v-for="item in feeOption" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog" type="primary" plain>取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      nameValue: '', // 搜索运费模板名称
      // 表格数据
      tableData: [],
      // 多商品运费设置弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        fee: '',
      },
      rules: {
        fee: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      feeOption: [{
        value: '1',
        label: '按最高商品运费计算'
      }, {
        value: '2',
        label: '按最低商品运费计算'
      },
      {
        value: '3',
        label: '按所有商品运费叠加计算'
      },],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getFreightTemplateList();
    this.freightTypeSetDetail();
  },
  methods: {
    // 多运费设置详情
    freightTypeSetDetail() {
      this.$axios.get(`${this.$api.freightTypeSetDetail}/${this.$store.state.userInfo.shopId}`).then((res) => {
        if (res.data.code == 100) {
          const { freightType } = res.data.result;
          this.$set(this.ruleForm, 'fee', String(freightType))
        }
      })
    },
    // 获取商品列表
    getFreightTemplateList() {
      this.loading = true;
      this.$axios.get(this.$api.getFreightTemplateList, {
        params: {
          templateName: this.nameValue || null,  // 搜索运费模板名称
          shopId: this.$store.state.userInfo.shopId,
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 多商品运费设置弹窗
    handleOpenDialog() {
      this.dialogVisible = true;
      this.freightTypeSetDetail();
    },
    handleSelectFee(value) {
      this.ruleForm.fee = value
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.addFreightTypeSet, {
            shopId: this.$store.state.userInfo.shopId, //shopId
            freightType: this.ruleForm.fee, // 类型
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('设置成功!');
              this.handleCloseDialog();
              this.getFreightTemplateList();
            }
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },


    // 关联商品
    handleRelate(row) {
      this.$router.push({ path: '/product/detail/relatedProducts', query: { id: row.id } })
    },
    // 修改
    handleModify(row) {
      this.$router.push({ path: '/product/detail/updateTemplate', query: { id: row.id } })
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(`${this.$api.deleteFreightTemplate}/${row.id}`)
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getFreightTemplateList()
          })
      }).catch(() => { });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getFreightTemplateList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onSearch();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getFreightTemplateList();
    },

  }

}



</script>

<style scoped lang="scss">
.shippingTemplate {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }

  }
}
</style>
